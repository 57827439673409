// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import { Turbo } from "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

window.Turbo = Turbo;

Rails.start();
ActiveStorage.start();

import "stylesheets/application";

import "controllers";

require("trix");
require("@rails/actiontext");

document.addEventListener("turbo:load", () => {
  document.addEventListener("trix-file-accept", (event) => {
    event.preventDefault();
  });
});
